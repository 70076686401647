import { Injectable, InjectionToken } from '@angular/core';
import { DataServiceBase } from 'src/app/core/data-service-base.class';
import { IColumnDef } from 'src/app/core/models/data-service/column-def.interface';
import { Class } from 'src/app/core/models/table-entities/class.interface';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { UserAccountService } from './user-account.service';
import { ILookupService } from 'src/app/core/models/data-service/lookup-service.interface';
import { LookupDataServiceBase } from 'src/app/core/lookup-data-service-base.class';
import { IClassState } from 'src/app/core/models/lookup-types/class-state.interface';
import { ClassStateLookupService } from '../lookup-services/class-state-lookup.service';
import { LectureDayLookupService } from '../lookup-services/lecture-day-lookup.service';
import { LectureTimeLookupService } from '../lookup-services/lecture-time-lookup.service';
import { ClassGradeLookupService } from '../lookup-services/class-grade-lookup.service';
import { IPromotionClassCreate } from 'src/app/core/models/table-entities/promotion-class-create.interface';
import { Observable, map, of } from 'rxjs';
import { ClassState } from 'src/app/core/constants/class-state.enum';

export const CLASS_SERVICE_TOKEN = new InjectionToken<DataServiceBase<Class>>(
  'ClassServiceToken'
);

@Injectable({
  providedIn: 'root',
})
export class ClassService extends LookupDataServiceBase<Class> {
  public override getDisplayName(data: Class): string {
    return data.name;
  }
  protected override getServiceEndpoint(): string {
    return 'class';
  }
  public override getColumnDefs(): IColumnDef[] {
    return [
      {
        label: 'Previous Class',
        dataKey: 'previousClassId',
        inGrid: false,
        lookup: this,
      },
      {
        label: 'Class Code',
        dataKey: 'code',
        inGrid: true,
        editor: 'description',
        required: true,
      },
      {
        label: 'Class Name',
        dataKey: 'name',
        inGrid: true,
        editor: 'description',
        required: true,
      },
      {
        label: 'Grade',
        dataKey: 'grade',
        inGrid: true,
        editor: 'select',
        lookup: this.classGradeLookup,
        required: true,
      },
      {
        label: 'Coordinator',
        dataKey: 'classCoordinatorName',
        inGrid: false,
        hideInDetail: true,
      },
      {
        label: 'Setup Complete',
        dataKey: 'setupComplete',
        inGrid: true,
        hideInDetail: true,
        customComponent: 'checkbox',
      },
      {
        label: 'Meeting Link',
        dataKey: 'meetingLink',
        inGrid: false,
        editor: 'description',
        required: true,
      },
      {
        label: 'Start Date',
        dataKey: 'startDate',
        inGrid: false,
        transform: this.transformToDateString,
        editor: 'date',
        required: true,
      },
      {
        label: 'End Date',
        dataKey: 'endDate',
        inGrid: false,
        transform: this.transformToDateString,
        editor: 'date',
        required: true,
      },
      {
        label: 'Lectue Day',
        dataKey: 'lectureDay',
        inGrid: false,
        editor: 'select',
        lookup: this.lectureDayLookup,
        required: true,
      },
      {
        label: 'Lectue Time',
        dataKey: 'lectureTime',
        inGrid: false,
        editor: 'select',
        lookup: this.lectureTimeLookup,
        required: true,
      },
      {
        label: 'State',
        dataKey: 'state',
        inGrid: true,
        lookup: this.classStatesService,
        editor: 'select',
        required: true,
      },
      {
        label: 'Created At',
        dataKey: 'createdAt',
        inGrid: false,
        transform: this.transformToDateString,
      },
      {
        label: 'Created By',
        dataKey: 'createdBy',
        inGrid: false,
        lookup: this.userAccountService,
      },
      {
        label: 'Updated At',
        dataKey: 'updatedAt',
        inGrid: false,
        transform: this.transformToDateString,
      },
      { label: 'Updated By', dataKey: 'updatedBy', inGrid: false },
    ];
  }

  constructor(
    configservice: ConfigService,
    http: HttpClient,
    private userAccountService: UserAccountService,
    private classStatesService: ClassStateLookupService,
    private lectureDayLookup: LectureDayLookupService,
    private lectureTimeLookup: LectureTimeLookupService,
    private classGradeLookup: ClassGradeLookupService
  ) {
    super(configservice, http);
    this.lectureTimeLookup.loadItems();
  }

  public getPromotingClass(classId: number) {}

  public createPromotingClass(
    createData: IPromotionClassCreate
  ): Observable<Class | undefined> {
    const currentClassData = Object.assign(
      {},
      this.getItemById(createData.previousClassId)
    );
    if (currentClassData) {
      currentClassData.grade++;
      currentClassData.startDate = createData.startDate
        .toISOString()
        .slice(0, 19);
      currentClassData.endDate = createData.endDate.toISOString().slice(0, 19);
      currentClassData.name = createData.className;
      currentClassData.createdBy = this.configService.getCurrentUserId();
      currentClassData.updatedBy = this.configService.getCurrentUserId();
      currentClassData.createdAt = '';
      currentClassData.updatedAt = '';
      currentClassData.previousClassId = createData.previousClassId;
      currentClassData.state = ClassState.New;
      return this.create(currentClassData) as Observable<Class | undefined>;
    }
    return of(undefined);
  }
}
